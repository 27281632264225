import * as React from 'react'

import Layout from '@components/layout'

const CaseStudies = () => {
  return (
    <Layout pageTitle="Case Studies">
      
    </Layout>
  )
}

export default CaseStudies